import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { format, subDays } from 'date-fns';

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import './index.scss';

const HistoryChart = ({ historyData }) => {
  const { t, i18n } = useTranslation();

  const [myData, setMyData] = useState('');
  const newData = useMemo(() => []);

  const dateFormat = (y, m, d) => format(new Date(y, m - 1, d), 'yy.MM.dd');
  const addData = useCallback(() => {
    // console.log(Object.keys(historyData[0]), 'aa');
    [...Array(Object.values(historyData[0]).length)].map((data, i) => {
      newData.push({
        name: dateFormat(
          Object.keys(historyData[0])[i].split('/')[2],
          Object.keys(historyData[0])[i].split('/')[0],
          Object.keys(historyData[0])[i].split('/')[1]
        ),
        confirmation: Object.values(historyData[0])[i],
        recovered: Object.values(historyData[2])[i],
        Death: Object.values(historyData[1])[i],
      });
    });
  }, [historyData]);

  const updateData = useCallback(() => {
    setMyData(newData.slice(0, newData.length));
  }, [newData]);

  useEffect(() => {
    if (!historyData) return;
    addData();
    updateData();
  }, [historyData]);

  return (
    <>
      <h3>{t('history')}</h3>

      <div className="barchart linechart">
        <article className="chartbox">
          <ResponsiveContainer width="100%" height={240}>
            <AreaChart
              width={100}
              height={240}
              data={myData}
              margin={{
                top: 0,
                right: 10,
                left: 10,
                bottom: 0,
              }}
              baseValue={10}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#ff7b7b" stopOpacity={1} />
                  <stop offset="100%" stopColor="#f84040" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#4346ff" stopOpacity={1} />
                  <stop offset="100%" stopColor="#3567e0" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorD" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#9b43ff" stopOpacity={1} />
                  <stop offset="100%" stopColor="#4315c2" stopOpacity={0} />
                </linearGradient>
              </defs>

              <CartesianGrid
                strokeDasharray="3 3"
                stroke="rgba(255,255,255,.1)"
              />

              <XAxis
                dataKey="name"
                fontSize={11}
                stroke="rgba(255,255,255,.4)"
                dy={10}
              />

              <YAxis
                dx={-5}
                fontSize={11}
                domain={[0, 'dataMax + 10000000']}
                // domain={['dataMin + 1000', 'dataMax + 2000']}
                stroke="rgba(255,255,255,.4)"
                // tickFormatter={formatter}
              />
              {/* <Tooltip cursor={false} /> */}
              <Tooltip
                labelStyle={{ color: '#bbb' }}
                contentStyle={{
                  background: 'rgba(16,17,29,.7)',
                  borderRadius: '5px',
                  padding: '5px 10px',
                  fontSize: '14px',
                  border: 0,
                }}
                formatter={(data) => data.toLocaleString()}
              />

              <Legend verticalAlign="top" align="right" />

              <Area
                type="monotone"
                dataKey={'confirmation'}
                // dataKey={t('confirmation')}
                stroke="#f84040"
                // unit="명"
                fillOpacity={0.2}
                fill="url(#colorUv)"
              />
              <Area
                type="monotone"
                dataKey={'recovered'}
                // dataKey={t('recovered')}
                stroke="#3567e0"
                // unit="명"
                fillOpacity={0.8}
                fill="url(#colorPv)"
              />
              {/* <Area
                    type="monotone"
                    dataKey={'Death'}
                    stroke="#6835e0"
                    unit="명"
                    fillOpacity={0.8}
                    fill="url(#colorD)"
                  /> */}
            </AreaChart>
          </ResponsiveContainer>
        </article>
      </div>

      {historyData && (
        <ul className="history-list">
          {[...Array(5)].map((data, i) => (
            <li>
              <span className="date">
                {dateFormat(
                  Object.keys(historyData[0]).reverse()[4 - i].split('/')[2],
                  Object.keys(historyData[0]).reverse()[4 - i].split('/')[0],
                  Object.keys(historyData[0]).reverse()[4 - i].split('/')[1]
                )}
              </span>
              <strong>
                {Object.values(historyData[0])
                  .reverse()
                  [4 - i].toLocaleString()}
              </strong>
              <span className="plus">
                +
                {Math.abs(
                  Object.values(historyData[0]).reverse()[4 - i + 1] -
                    Object.values(historyData[0]).reverse()[4 - i]
                ).toLocaleString()}
              </span>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default HistoryChart;
